<template>
  <b-container class="user-header-menu">
    <b-row>
      <b-col cols="3" class="pr-0" >
        <b-avatar 
        badge-variant="info" :src="userMeInfo.avatar"
        style="size:20px; min-width: 60%;"
        
        > </b-avatar>
      </b-col>
      <b-col cols="9" style="margin-left:-20px">
        <b-row>
          <b-col cols="12">
            <p class="user-menu-first">
              
              <router-link
                  :to="'/app/user/profile/' + GET_USER_INFO.uid"
                  style="text-decoration:none; text-transform:capitalize;"
                 
                  > {{shorter( userMeInfo.name , userMeInfo.surname) }} |
              <span class="pl-2"> {{ userMeInfo.company.name }}</span></router-link
                >   
            </p>
            <p class="user-menu-second">{{ userMeInfo.email }}</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <p class="user-menu-third">
          {{ $t('headermenu.plan') }}: <span>{{ userMeInfo.plan.name }}</span>
          
          <a 
            href="/app/profile/settings/plan" 
            v-if="GET_USER_INFO.plan.upgrade == true"  
            class="user-menu-fourth"
          >
            {{ $t('headermenu.upgrade') }}
          </a>
        </p>
      </b-col>
    </b-row>
    <hr style="margin-top: 10px" />
    <b-row>
      <b-col cols="12">
        <ul class="sub-menu" tabindex="0">
          <!--jimir3x-->
          <div class="user-menu__mobile-actions">
            <div class="user-menu__request-button">
              <div
                class="header__request-button"
                @click="openAddItemModal"
                v-if="userRoleType === 'BUYER'"
              >
                <span class="header__request-button-text">{{
                  $t('buttons.cr')
                }}</span>
                <img
                  class="header__request-button-icon"
                  src="/img/requ-btn-header.svg"
                  alt=""
                />
              </div>

              <div
                class="header__request-button"
                @click="openAddItemModal"
                v-if="userRoleType === 'SELLER'"
              >
                <span class="header__request-button-text">{{
                  $t('buttons.cq')
                }}</span>
                <img
                  class="header__request-button-icon"
                  src="/img/requ-btn-header.svg"
                  alt=""
                />
              </div>
            </div>

            <!-- BUYER to SELLER -->
            <div
              class="header__navigation-link"
              v-if="userRoleType === 'BUYER'"
              @click="changeRoleType('SELLER')"
            >
              <div class="header__navigation-img-container">
                <img
                  class="header__navigation-img"
                  src="/img/switch-account.svg"
                  alt=""
                />
              </div>
              <span class="header__navigation-link-text">{{
                $t('header.buyer')
              }}</span>
            </div>
            <!-- SELLER to BUYER -->
            <div
              class="header__navigation-link"
              v-if="userRoleType === 'SELLER'"
              @click="changeRoleType('BUYER')"
            >
              <div class="header__navigation-img-container">
                <img
                  class="header__navigation-img"
                  src="/img/switch-account.svg"
                  alt=""
                />
              </div>
              <span class="header__navigation-link-text">{{
                $t('header.seller')
              }}</span>
            </div>
          </div>
          <hr />
          <!--jimir3x-->
           <li class="sub" >
             <img
                  class="header__navigation-img"
                  src="/img/my-requests.svg"
                  alt=""
                />
            <router-link to="/app/my/requests">{{
              $t('headermenu.myrequests')
            }}</router-link>
          </li>
           <li class="sub">
             <img
                  class="header__navigation-img"
                  src="/img/my-quotes.svg"
                  alt=""
                />
              
            <router-link to="/app/my/listings">{{
              $t('headermenu.myquotes')
            }}</router-link>
          </li>
        
          <li class="sub">
             <img
                  class="header__navigation-img"
                  src="/img/message-try.svg"
                  alt=""
                />
            <router-link :to="'/app/messenger'">{{
              $t('headermenu.messages')
            }}</router-link>
          </li>
          <hr>
          <li class="sub">
             <svg style="width:24px;height:24px; color:#198cff" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9M14 20H2V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M7 9C7 9.55 7.45 10 8 10C8.55 10 9 9.55 9 9C9 8.45 8.55 8 8 8C7.45 8 7 8.45 7 9M4 18H12C12 16.9 10.21 16 8 16C5.79 16 4 16.9 4 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4Z" />
</svg>
            <router-link to="/app/my/connections">{{
              $t('headermenu.connections')
            }}</router-link>
          </li>

          <!-- Search by User ID... -->

           <!-- <div style="position:static">
          <input spellcheck="true" 
            class="dashboard-searchbar-submenu btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center btn-location-change"
            placeholder="Search by User ID..." 
            style="" 
            v-model="uid"
            @keyup.enter="doSearch"
            type="text" 
            name="" 
            id=""
          >
           <div v-if="uidError">Invalid UID</div>
        </div> -->

         <!-- Search by User ID... -->

          <li class=" w-100 seperator-user-menu">
            <hr />
          </li>

          <li class="sub">
          
            {{ $t('headermenu.account') }}
            <!-- <router-link to="/app/profile/settings"
              >Account Settings
            </router-link> -->
          </li>

          <li class="sub">
              <svg style="width:24px;height:24px; color:#a7a7a7" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10 12C12.21 12 14 10.21 14 8S12.21 4 10 4 6 5.79 6 8 7.79 12 10 12M10 6C11.11 6 12 6.9 12 8S11.11 10 10 10 8 9.11 8 8 8.9 6 10 6M9.27 20H2V17C2 14.33 7.33 13 10 13C11.04 13 12.5 13.21 13.86 13.61C13 13.95 12.2 14.42 11.5 15C11 14.94 10.5 14.9 10 14.9C7.03 14.9 3.9 16.36 3.9 17V18.1H9.22C9.2 18.15 9.17 18.2 9.14 18.25L8.85 19L9.14 19.75C9.18 19.83 9.23 19.91 9.27 20M17 18C17.56 18 18 18.44 18 19S17.56 20 17 20 16 19.56 16 19 16.44 18 17 18M17 15C14.27 15 11.94 16.66 11 19C11.94 21.34 14.27 23 17 23S22.06 21.34 23 19C22.06 16.66 19.73 15 17 15M17 21.5C15.62 21.5 14.5 20.38 14.5 19S15.62 16.5 17 16.5 19.5 17.62 19.5 19 18.38 21.5 17 21.5Z" />
</svg>
            <router-link
              to="/app/profile/settings/profile"
              class="user-header-menu-grey"
            >
              {{ $t('headermenu.profile') }}
            </router-link>
          </li>

          <li class="sub">
            <svg style="width:24px;height:24px; color:#a7a7a7" viewBox="0 0 24 24">
    <path fill="currentColor" d="M23 12L20.6 9.2L20.9 5.5L17.3 4.7L15.4 1.5L12 3L8.6 1.5L6.7 4.7L3.1 5.5L3.4 9.2L1 12L3.4 14.8L3.1 18.5L6.7 19.3L8.6 22.5L12 21L15.4 22.5L17.3 19.3L20.9 18.5L20.6 14.8L23 12M18.7 16.9L16 17.5L14.6 19.9L12 18.8L9.4 19.9L8 17.5L5.3 16.9L5.5 14.1L3.7 12L5.5 9.9L5.3 7.1L8 6.5L9.4 4.1L12 5.2L14.6 4.1L16 6.5L18.7 7.1L18.5 9.9L20.3 12L18.5 14.1L18.7 16.9M16.6 7.6L18 9L10 17L6 13L7.4 11.6L10 14.2L16.6 7.6Z" />
</svg>
            <router-link
              to="/app/profile/settings/verification"
              class="user-header-menu-grey"
            >
              {{ $t('headermenu.verification') }}
            </router-link>
          </li>

          <li class="sub">
            <svg style="width:24px;height:24px; color:#a7a7a7" viewBox="0 0 24 24">
    <path fill="currentColor" d="M17,18V5H7V18L12,15.82L17,18M17,3A2,2 0 0,1 19,5V21L12,18L5,21V5C5,3.89 5.9,3 7,3H17M11,7H13V9H15V11H13V13H11V11H9V9H11V7Z" />
</svg>
            <router-link
              to="/app/profile/settings/plan"
              class="user-header-menu-grey"
            >
              {{ $t('headermenu.p&b') }}
            </router-link>
          </li>

          <li class="sub">
            <svg style="width:24px;height:24px; color:#a7a7a7" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21 6.5C21 8.43 19.43 10 17.5 10S14 8.43 14 6.5 15.57 3 17.5 3 21 4.57 21 6.5M11.5 22C12.61 22 13.5 21.11 13.5 20H9.5C9.5 21.11 10.4 22 11.5 22M18 12C17.84 12 17.67 12 17.5 12C17 12 16.5 11.92 16 11.79V17H7V10.5C7 8 9 6 11.5 6C11.68 6 11.85 6.03 12 6.05C12.1 5.06 12.45 4.14 13 3.37C12.92 2.6 12.28 2 11.5 2C10.67 2 10 2.67 10 3.5V4.18C7.13 4.86 5 7.43 5 10.5V16L3 18V19H20V18L18 16V12Z" />
</svg>
            <router-link
              to="/app/profile/settings/notifications"
              class="user-header-menu-grey"
            >
              {{ $t('headermenu.nots') }}
            </router-link>
          </li>

          <li class="sub">
            <svg style="width:24px;height:24px; color:#a7a7a7" viewBox="0 0 24 24">
    <path fill="currentColor" d="M5.83,10C5.42,8.83 4.31,8 3,8A3,3 0 0,0 0,11A3,3 0 0,0 3,14C4.31,14 5.42,13.17 5.83,12H8V14H10V12H11V10H5.83M3,12A1,1 0 0,1 2,11A1,1 0 0,1 3,10A1,1 0 0,1 4,11A1,1 0 0,1 3,12M16,4A4,4 0 0,0 12,8A4,4 0 0,0 16,12A4,4 0 0,0 20,8A4,4 0 0,0 16,4M16,10.1A2.1,2.1 0 0,1 13.9,8A2.1,2.1 0 0,1 16,5.9C17.16,5.9 18.1,6.84 18.1,8C18.1,9.16 17.16,10.1 16,10.1M16,13C13.33,13 8,14.33 8,17V20H24V17C24,14.33 18.67,13 16,13M22.1,18.1H9.9V17C9.9,16.36 13,14.9 16,14.9C18.97,14.9 22.1,16.36 22.1,17V18.1Z" />
</svg>
            <router-link
              to="/app/profile/settings/privacy"
              class="user-header-menu-grey"
            >
              {{ $t('headermenu.privacy') }}
            </router-link>
          </li>

          <!--<li class="sub">
            <router-link to="/"> Support </router-link>
          </li> -->

          <li class=" w-100 seperator-user-menu">
            <hr />
          </li>

          <!-- <li class="sub">
            <router-link to="/">
              Support <b-img src="/img/footer-lang.png"></b-img>
            </router-link>
          </li> -->

          <li class="sub">
            <svg style="width:24px;height:24px;  color:#a7a7a7" viewBox="3 0 24 24">
    <path fill="currentColor" d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z" />
</svg>
            <a @click="changeCurrency()" class="user-header-menu-grey">
              {{ $t('headermenu.currency') }}
            </a>
          </li>

          <li class="sub">
            <!-- <svg style="width:24px;height:24px; color:#a7a7a7" viewBox="0 0 24 24">
    <path fill="currentColor" d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
</svg> -->

 <img
                  class="header__navigation-img"
                  src="/img/lang8.svg"
                  alt=""
                  size="24"
                />
            <a @click="changeLanguage()" class="user-header-menu-grey">
              {{ $t('headermenu.language') }}
            </a>
          </li>

          <li class="sub">
            <svg style="width:24px;height:24px; color:#a7a7a7" viewBox="0 0 24 24">
    <path fill="currentColor" d="M9,11H15V8L19,12L15,16V13H9V16L5,12L9,8V11M2,20V4H4V20H2M20,20V4H22V20H20Z" />
</svg>
            <a @click="changeDistanceOpen()" class="user-header-menu-grey">
              {{ $t('headermenu.distance') }}
            </a>
          </li>

          <li class=" w-100 seperator-user-menu">
            <hr />
          </li>

          <li class="sub">
            <a @click.prevent="logout" href="#">{{
              $t('headermenu.signout')
            }}</a>
          </li>

          <!--<li class="sub">
            <router-link to="/"> Home </router-link>
          </li>
          <li v-if="userRoleType === 'BUYER'" class="sub">
            <router-link to="/app/my/requests">My Requests </router-link>
          </li>
          <li class="sub" v-if="userRoleType === 'SELLER'">
            <router-link to="/app/my/listings"> My Listings </router-link>
          </li>

          <li class="sub">
            <a href="#">Help</a>
          </li>
          <li class="sub w-100">
            <hr />
          </li>

          <li v-if="userRoleType === 'BUYER'" class="sub">
            <a @click.prevent="changeRoleType('SELLER')" href="#"
              >Switch to seller</a
            >
          </li>
          <li v-if="userRoleType === 'SELLER'" class="sub">
            <a @click.prevent="changeRoleType('BUYER')" href="#"
              >Switch to buyer</a
            >
          </li>
          <li class="sub">
            <a @click.prevent="logout" href="#">Logout</a>
          </li> -->
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import USER_INFO from '@/graphql/user/userInfo.graphql';
import _ from 'lodash';
export default {
  data() {
    return {
      userMeInfo: [],
      uid: null,
      uidError: false
    };
  },
  methods: {
    changeDistanceOpen() {
      this.$emit('opening-modal');
    },
    changeLanguage() {
      this.$emit('opening-modal-language');
    },
    changeCurrency() {
      this.$emit('opening-modal-currency');
    },
    RoleType(type) {
      window.localStorage.setItem('user_type', type);
      location.reload();
    },
    logout() {
      window.localStorage.clear();
      this.$store.commit('UNSET_USER_INFO');
      this.$router.push('/auth/login');
    },
    async doSearch() {
        if (!/^\d{10}$/i.test(this.uid)) {
            return this.uidError = true;
        }

        let selector = await this.$apollo.query(USER_INFO, { uid: this.uid });
        let info = selector('**.info');
        if (_.isNil(info)) {
            return this.uidError = true;
        }

        this.getUserProfile(info.uid);
    }
  },

  async created() {
    let userInfo = this.GET_USER_INFO;
    this.userMeInfo = userInfo;
    console.log(this.userMeInfo);
  },
};
</script>
