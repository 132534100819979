<template>
  <!--jimir3x-->
  <ul
    class="sub-menu notification-wrapper notification-general-div notification-wrapper--extra"
  >
    <!--jimir3x-->
    <!--<h3 class="notification-wrapper-title text-left">Notifications</h3> -->
    <loading :loading="loading" />
    <div v-if="!loading" style="padding: 10px" class="">
      <b-tabs
        v-model="tabIndex"
        class="w-100 notification-badge-container"
        content-class=""
      >
        <b-tab title="General">
          <template #title>
            {{$t("buttons.from-us")}}
            <!-- <b-badge variant="primary">
                <span class="badge-inner-circle"></span
              ></b-badge> -->

            <b-spinner
              v-if="checkCount('GENERAL') > 0"
              type="grow"
              small
            >
            </b-spinner>
          </template>
          <div v-if="$_.size(notifications) === 0" class="notification-wrapper-content">
             <img 
                class="float-left no-not" 
                src="/img/no-not.svg"
                height="50"
             >
            
             <p class="ml-2 float-left no-not-text">{{$t("header.no-not-text")}}</p>
          </div>
          <div v-else class="notification-wrapper-content">
            <transition-group
              name="custom-classes-transition"
              enter-active-class="animated"
              leave-active-class="animated bounceOutRight"
            >
              <div class="item" v-for="item in notifications" :key="item._id">
                <img
                  :src="getAvatar(item.from.avatar, 32)"
                  alt=""
                  width="32"
                  height="32"
                  class="circle-avatar"
                />
                <div class="desc">
                  <p class="text-capitalize">
                    {{ item.from.name }} {{ item.from.surname }}  </p>
                   <p> {{ getNotifyMessage(item.action) }}
                    <span class="status"></span>
                  </p>
                  <span>{{ item.created_at }}</span>
                </div>
                <b-img
                  class="ml-4"
                  @click.prevent="remove(item._id)"
                  src="/img/home-close.png"
                >
                </b-img>
              </div>
            </transition-group>
          </div>
        </b-tab>
        
        <b-tab title="Users">
          <template #title>
            {{$t("buttons.users")}}
            <b-spinner
              v-if="checkCount('USERS') > 0"
              type="grow"
              small
            >
            </b-spinner>
          </template>
          <div v-if="$_.size(notifications) === 0" class="notification-wrapper-content">
             <img 
                class="float-left no-not" 
                src="/img/no-not.svg"
                height="50"
             >
            
             <p class="ml-2 float-left no-not-text">{{$t("header.no-not-text")}}</p>
          </div>
          <div v-else class="notification-wrapper-content">
            <transition-group
              name="custom-classes-transition"
              enter-active-class="animated"
              leave-active-class="animated bounceOutRight"
            >
              <a 
                class="item" 
                style="text-decoration: none;"
                v-for="item in notifications" 
                :key="item._id"
                @click.prevent="redirect(item)"
              >
                <img
                  :src="getAvatar(item.from.avatar, 32)"
                  alt=""
                  width="32"
                  height="32"
                  class="circle-avatar"
                />
                <div class="desc">
                  <p class="text-capitalize">
                    {{ item.from.name }} {{ item.from.surname }}  </p>
                   <p> {{ getNotifyMessage(item.action) }}
                    <span class="status"></span>
                  </p>
                  <span>{{ item.created_at }}</span>
                </div>
                <b-img
                  class="ml-4"
                  @click.prevent="remove(item._id)"
                  src="/img/home-close.png"
                ></b-img>
              </a>
            </transition-group>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div class="notification-bottom-bar">
      <a href="javascript:void(0)" v-if="settings.sound === true" @click="changeSoundSettings(false)">
        <img src="/img/notif-mute.svg" class="img-fluid" alt="" />
      </a>
      <a v-else @click="changeSoundSettings(true)" href="javascript:void(0)">
        <img src="/img/alert-icon-off.svg" class="img-fluid" alt="" />
      </a>
      <a 
        class="notification-bar-setting-icon" 
        href="javascript:void(0)"
        @click.prevent="$router.push('/app/profile/settings/notifications')"
      >
        <img
          src="/img/notif-settings.svg"
          class="notification-bar-setting-icon"
          alt=""
        />
      </a>
    </div>
  </ul>
</template>

<script>
import GET_NOTIFICATIONS from '@/graphql/me/getNotification.graphql';
import COUNT_UNREADS from '@/graphql/me/notificationCountUnreads.graphql';
import READ from '@/graphql/me/notificationRead.graphql';
import GET_NOTIFY_SETTINGS from "@/graphql/me/profile/getNotify.graphql";
import UPDATE_NOTIFY_SETTINGS from "@/graphql/me/profile/updateNotify.graphql";
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      tabIndex: 0,
      loading: false,
      limit: 100,
      cursor: null,
      type: 'GENERAL',
      settings: {
        sound: false,
        email: {
            follow: false,
            message: false,
            connectter: false,
            connections_product: false,
            connections_inquiry: false
        },
        desktop: {
            follow: false,
            message: false,
            connectter: false,
            connections_product: false,
            connections_inquiry: false
        },
      }
    };
  },

  computed: {
    ...mapGetters('notifications', ['notifications', 'counts']),
  },

  methods: {
    getNotifyMessage(type) {
      switch (type) {
          case 'unfollow':
              return 'unfollowed you';
              break;
          case 'follow':
              return 'followed you';
              break;
          case 'message':
              return 'sent you a message';
              break;
          case 'connections_inquiry':
              return 'new request upload';
              break;
          case 'connections_product':
              return 'new quote upload';
              break;
          default:
              return 'ok';
              break;
      }
    },
    async getNotifyList() {
      try {
        // this.loading = true;
        let data = await this.$apollo.query(
            GET_NOTIFICATIONS, 
            { 
                limit: this.limit, 
                cursor: null, 
                type: this.type === 'USERS' ? ['SELLER', 'BUYER']: [this.type]
            }
        );
        let response = data('**.list');
        this.cursor = response.next_cursor;
        this.$store.commit('notifications/SET_NOTIFICATIONS', response.list);
      } catch (e) {
        console.log(e);
      } finally {
        // this.loading = false;
      }
    },

    async count(type) {
      try {
        // this.loading = true;
        let data = await this.$apollo.query(COUNT_UNREADS, { type: type === 'USERS' ? ['SELLER', 'BUYER']: [type] });
        let count = data('**.countUnreads');
        this.$store.commit('notifications/SET_COUNT', { type, count });
      } catch (e) {
        console.log(e);
      } finally {
        // this.loading = false;
      }
    },

    async read(type) {
      try {
        await this.$apollo.mutate(READ, { type: type === 'USERS' ? ['SELLER', 'BUYER']: [type] });
        this.$store.commit('notifications/SET_COUNT', { type: 'USERS', count: 0 });
      } catch (e) {
        console.log(e);
      } finally {
        // this.loading = false;
      }
    },

    async remove(_id) {
      try {
        this.$store.dispatch('notifications/DELETE', { vm: this, _id: _id });
      } catch (e) {
        console.log(e);
      }
    },

    checkCount(type) {
      return _.get(_.find(this.counts, x => type == x.type), 'count', 0);
    },

    async getNotifySettings() {
      let data = await this.$apollo.query(GET_NOTIFY_SETTINGS);
      this.settings = data('**.get', true);
    },

    // ///EXAMPLE TOASTER///
    
    async saveNotifySettings() {
      try {
        await this.$apollo.mutate(UPDATE_NOTIFY_SETTINGS, { notify: this.settings });
        return this.$bvToast.toast(this.$t("Toaster.notifications"), {
          title: this.$t("Toaster.not-title"),
          variant: "success",
          autoHideDelay: 1350,
        });
      } catch (e) {
        
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
      }
    },

    async changeSoundSettings(value) {
        await this.getNotifySettings();
        this.settings.sound = value;
        this.saveNotifySettings();
    },
    redirect(item) {
        switch (item.action) {
          case 'unfollow':
              this.getUserProfile(item.from.uid);
              break;
          case 'follow':
              this.getUserProfile(item.from.uid);
              break;
          case 'message':
              this.startConversation(item.from.uid);
              break;
          case 'connections_inquiry':
              this.getUserProfile(item.from.uid);
              break;
          case 'connections_product':
              this.getUserProfile(item.from.uid);
              break;
          default:
              this.getUserProfile(item.from.uid);
              break;
        }
    }
  },
  watch: {
    async tabIndex(val) {
      this.isNewNotify = {
        status: false,
        type: null,
      };

      if (val === 0) {
        this.type = 'GENERAL';
      }
      if (val === 1) {
        this.type = 'USERS';
      }

      this.cursor = null;
      this.$store.commit('notifications/CLEAR_NOTIFICATIONS');
      await this.$nextTick();
      await this.getNotifyList();
      await this.read(this.type);
    },
  },
  beforeMount() {
      this.tabIndex = 0;
      this.$store.commit('notifications/CLEAR_NOTIFICATIONS');
  },
  async created() {
    await this.count('GENERAL');
    await this.count('USERS');
    await this.getNotifySettings();
  },
};
</script>
